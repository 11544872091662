<template>
  <div class="oilwax-calculator">
    <div class="flex flex-col gap-8">
      <div class="flex justify-between items-center">
        <div class="flex gap-2 items-center">
          <div class="flex gap-2 d-print-none">
            <div v-if="reprint">Editing existing recipe</div>
            <div v-else>Creating new recipe</div>
          </div>
        </div>
        <div class="print-date">
          <div v-if="reprint">
            {{ reprint.print_date | isodate }}
          </div>
          <div v-else>{{ currentDate }}</div>
        </div>
      </div>
      <table class="oilwax-input">
        <tr>
          <td><span class="mr-4">Brand / Wax:</span></td>
          <td>
            <b-form-select
              v-model="calculator.candle_brand"
              :options="options.candle_brand"
              size="sm"
            ></b-form-select>
          </td>
          <td>
            <div style="margin-right: 32px;">
              <b-form-select
                v-model="calculator.wax"
                :options="options.wax"
                size="sm"
              ></b-form-select>
            </div>
          </td>
        </tr>

        <tr>
          <td><span class="mr-4">Container:</span></td>
          <td>
             <b-form-select
              v-model="calculator.container"
              :options="options.container"
              size="sm"
              @change="fillValue('container', 'container_mass')"
            ></b-form-select>
          </td>
          <td>
            <div>
               <b-input-group size="sm" append="g">
                <b-form-input
                  v-model="calculator.container_mass"
                  autocomplete="off"
                  class="text-right"
                  @change="calculateValues()"
                ></b-form-input>
              </b-input-group>
            </div>
          </td>
        </tr>

        <tr>
          <td>Dye:</td>
          <td>
            <v-select
              v-model="calculator.colorant"
              :options="options.colorant"
              size="sm"
              @input="fillValue('colorant', 'colorant_pct')"
              label="text"
              :reduce="option => option.value"
            />
          </td>
          <td>
            <div>
              <b-input-group size="sm" append="%">
                <template #prepend>
                  <b-input-group-text v-if="calculator.minwax">
                    MW: {{ roundNumber(calculator.minwax, 0, 0) }} g
                  </b-input-group-text>
                </template>
                <b-form-input
                  v-model="calculator.colorant_pct"
                  autocomplete="off"
                  class="text-right"
                  @change="calculateValues()"
                ></b-form-input>
              </b-input-group>
            </div>
          </td>
        </tr>

        <tr>
          <td>Fragrance:</td>
          <td>
            <v-select
              v-model="calculator.fragrance"
              label="text"
              :options="options.fragrance"
              :reduce="option => option.value"
              @input="calculateValues()"
            />
          </td>
          <td>
            <div>
              <b-input-group size="sm" append="%">
                <b-form-select
                  v-model="calculator.fragrance_pct"
                  :options="options.fragrance_pct"
                  size="sm"
                  class="text-right"
                  @change="calculateValues()"
                ></b-form-select>
              </b-input-group>
            </div>
          </td>
        </tr>

        <tr>
          <td>Additive 1:</td>
          <td>
            <b-form-select
              v-model="calculator.additive1"
              :options="options.additive"
              size="sm"
              @change="calculateValues()"
            ></b-form-select>
          </td>
          <td>
            <div>
              <b-input-group size="sm" append="%">
                <b-form-select
                  v-model="calculator.additive1_pct"
                  :options="options.additive_pct"
                  size="sm"
                  class="text-right"
                  @change="calculateValues()"
                ></b-form-select>
              </b-input-group>
            </div>
          </td>
        </tr>

        <tr>
          <td>Additive 2:</td>
          <td>
            <b-form-select
              v-model="calculator.additive2"
              :options="options.additive"
              size="sm"
              @change="calculateValues()"
            ></b-form-select>
          </td>
          <td>
            <div>
              <b-input-group size="sm" append="%">
                <b-form-select
                  v-model="calculator.additive2_pct"
                  :options="options.additive_pct"
                  size="sm"
                  class="text-right"
                  @change="calculateValues()"
                ></b-form-select>
              </b-input-group>
            </div>
          </td>
        </tr>

        <tr>
          <td><span class="mr-4 text-nowrap">Number of Candles:</span></td>
          <td>
            <div class="w-50">
              <b-input-group size="sm">
                <b-form-input
                  v-model="calculator.candle_count"
                  autocomplete="off"
                  class="text-right"
                  size="sm"
                  @change="calculateValues()"
                ></b-form-input>
              </b-input-group>
            </div>
          </td>
          <td>
            <div class="text-right font-bold" v-if="calculator.option_two.minwax">
              <span>Weight Total: </span>
              <span :class="weightTotalClass">
                {{ roundNumber(calculator.option_two.total * calculator.option_two.count, 0, 2) }} g
                /
                {{ roundNumber(calculator.option_two.minwax, 0, 2) }} g
              </span>
            </div>
          </td>
        </tr>
      </table>

      <div class="flex justify-between gap-4 d-print-none">
        <div>
          <b-button
            variant="white"
            size="sm"
            @click="fragranceCalculator()"
            :disabled="
              isLoading
              || calculator.fragrance_pct.length === 0
              || calculator.container_mass.length === 0
            "
          >
            Fragrance Calc
          </b-button>
        </div>
        <div class="flex gap-2">
          <b-button
            variant="primary"
            size="sm"
            @click="reset()"
            :disabled="isLoading"
          >
            Start new recipe
          </b-button>

          <b-button
            variant="primary"
            size="sm"
            @click="print()"
            :disabled="isLoading || !canSave"
          >
            Print
          </b-button>

          <b-button
            variant="primary"
            size="sm"
            @click="print(true)"
            :disabled="isLoading || !canSave"
          >
            <span v-if="reprint">
              Print & Edit
            </span>
            <span v-else>
              Print & Save
            </span>
          </b-button>
        </div>
      </div>

      <div class="flex flex-col gap-2">
        <div>
          <h2 class="text-xl font-bold border-b-2 border-black">
            Option 1 - Oil as a percentage of Wax mass
          </h2>
          <!-- eslint-disable max-len -->
          <div>
            Use this option if you want to specify the percentage of oil relative to the mass of the candle wax.
            This can be useful if you are adding fragrance close to the maximum scent load for the wax.
            It is also the easiest to calculate manually. i.e. at 10% loading, 1 kg of wax you will need 100g of oil.
          </div>
          <!-- eslint-enable max-len -->
        </div>
        <option-table :value="calculator.option_one" />
      </div>

      <div class="flex flex-col gap-2">
        <div>
          <h2 class="text-xl font-bold border-b-2 border-black">
            Option 2 - Oil as a percentage of total Candle mass
          </h2>
          <!-- eslint-disable max-len -->
          <div>
            Use this option if you want to specify the percentage of oil relative to the total mass of the candle.
            This means that the percentage of oil, relative to the wax, will be higher, so take care when working close to maximum wax loading.
            This method is - arguably - more intuitive. Particularly amongst non candle-makers.
          </div>
          <!-- eslint-enable max-len -->
        </div>
        <option-table :value="calculator.option_two" />
      </div>

      <div class="flex flex-col gap-2 d-none">
        <div>
          <h2 class="text-xl font-bold border-b-2 border-black">
            <div class="flex justify-between">
              <div>Pouring solution</div>
              <div v-if="dyeTotal > 0">
                <span>
                  Corob: {{ roundNumber(Math.max(pouringOptionsWeight, minwax), 0, 0) }} g
                </span>
                <span v-if="pouringOptionsWeight < minwax">
                  ({{ roundNumber(pouringOptionsWeight, 0, 0)}} g)
                </span>
              </div>
            </div>
          </h2>
        </div>
        <table v-if="pouringOptions.length === 0">
          <tr>
            <td>No pouring solution available</td>
          </tr>
        </table>
        <table v-else>
          <tr>
            <td class="text-nowrap row-fit"><span class="mr-2">Pitcher</span></td>
            <template v-for="(pouringOption, index) in pouringOptions">
              <td :key="`space_${index}_jar`"><span class="mx-2"></span></td>
              <td
              :key="`${index}_jar`"
              class="text-nowrap ml-2 font-bold"
            >
              {{ pouringOption.jar_name }}
            </td>
            </template>
          </tr>

          <tr>
            <td class="text-nowrap row-fit"><span class="mr-2">Fragrance</span></td>
            <template v-for="(pouringOption, index) in pouringOptions">
              <td :key="`space_${index}_fragrance`"><span class="mx-2"></span></td>
              <td
                :key="`${index}_fragrance`"
                class="text-nowrap ml-2 oilwax-border text-right"
              >
                <span>
                  {{ roundNumber(pouringOption.fragrance, 0, 2) }} g
                </span>
              </td>
            </template>
          </tr>

          <tr>
            <td class="text-nowrap row-fit"><span class="mr-2">Wax</span></td>
            <template v-for="(pouringOption, index) in pouringOptions">
              <td :key="`space_${index}_wax`"><span class="mx-2"></span></td>
              <td
                :key="`${index}_wax`"
                class="text-nowrap ml-2 oilwax-border text-right"
              >
                <span>
                  {{ roundNumber(pouringOption.wax, 0, 2) }} g
                </span>
              </td>
            </template>
          </tr>

          <tr>
            <td class="text-nowrap row-fit"><span class="mr-2">Dye</span></td>
            <template v-for="(pouringOption, index) in pouringOptions">
              <td :key="`space_${index}_dye`"><span class="mx-2"></span></td>
              <td
                :key="`${index}_dye`"
                class="text-nowrap ml-2 oilwax-border text-right"
              >
                <span>
                  {{ roundNumber(pouringOption.dye, 0, 2) }} g
                </span>
              </td>
            </template>
          </tr>

          <tr>
            <td class="text-nowrap row-fit"><span class="mr-2">Additive 1</span></td>
            <template v-for="(pouringOption, index) in pouringOptions">
              <td :key="`space_${index}_additive1`"><span class="mx-2"></span></td>
              <td
                :key="`${index}_additive1`"
                class="text-nowrap ml-2 oilwax-border text-right"
              >
                <span>
                  {{ roundNumber(pouringOption.additive1, 0, 2) }} g
                </span>
              </td>
            </template>
          </tr>

          <tr>
            <td class="text-nowrap row-fit"><span class="mr-2">Additive 2</span></td>
            <template v-for="(pouringOption, index) in pouringOptions">
              <td :key="`space_${index}_additive2`"><span class="mx-2"></span></td>
              <td
                :key="`${index}_additive2`"
                class="text-nowrap ml-2 oilwax-border text-right"
              >
                <span>
                  {{ roundNumber(pouringOption.additive2, 0, 2) }} g
                </span>
              </td>
            </template>
          </tr>

          <tr>
            <td class="text-nowrap row-fit"><span class="mr-2 font-bold">Total</span></td>
            <template v-for="(pouringOption, index) in pouringOptions">
              <td :key="`space_${index}_total`"><span class="mx-2"></span></td>
              <td
                :key="`${index}_total`"
                class="text-nowrap ml-2 oilwax-border text-right font-bold"
              >
                <span>
                  {{ roundNumber(pouringOption.total, 0, 2) }} g
                </span>
              </td>
            </template>
          </tr>
        </table>
      </div>

      <!-- POURING SOLUTION ACTIVE -->
      <div class="flex flex-col gap-2">
        <div class="flex gap-4 justify-between border-b-2 border-black">
          <h2 class="text-xl font-bold">
            Pouring solution
          </h2>
          <div>

            <b-input-group size="sm" class="mb-2">
              <b-input-group-prepend>
                <b-button
                  variant="primary"
                  title="Calculate ideal jar size"
                  @click="calculateCustomIdealJarSize()"
                  size="sm"
                >
                  <i class="fas fa-calculator"></i>
                </b-button>
              </b-input-group-prepend>
              <b-form-input
                v-model="calculator.custom_jar_size"
                autocomplete="off"
                size="sm"
                class="text-right"
                placeholder="Custom jar"
                @change="calculateValues()"
              ></b-form-input>
            </b-input-group>
          </div>
        </div>

        <table>
          <tr>
            <td class="text-nowrap row-fit"><span style="margin-right: 80px;">Pitcher</span></td>
            <template v-for="(pouringOption, index) in simplePoursFiltered">
              <td
                v-if="simplePoursFiltered.length !== 1"
                :key="`space_${index}_jar`"
              >
                <span class="mx-2"></span>
              </td>
              <td
                :key="`${index}_jar`"
                class="text-nowrap ml-2 font-bold"
              >
                <div class="flex gap-2 justify-between">
                  <span>
                    <span>
                      <span>{{ pouringOption.amount | format }}x</span>
                      <span>{{ pouringOption.jar_name }}</span>
                    </span>
                  </span>
                  <span v-if="pouringOption.jars">
                    {{ pouringOption.jars | format }}xJars
                  </span>
                </div>
              </td>
              <td
                v-if="simplePoursFiltered.length === 1"
                :key="`space2_${index}_jar`"
              >
                <span class="mx-2"></span>
              </td>
            </template>
          </tr>

          <template v-for="simplePourField in simplePourFields">
            <tr :key="`${simplePourField.name}`">
              <td class="text-nowrap row-fit">
                <span class="mr-2" :class="{ 'font-bold': simplePourField.bold }">
                  {{ simplePourField.text }}
                </span>
              </td>
              <template v-for="(pouringOption, index) in simplePoursFiltered">
                <td
                  v-if="simplePoursFiltered.length !== 1"
                  :key="`space_${index}_${simplePourField.name}`"
                >
                  <span class="mx-2"></span>
                </td>
                <td
                  :key="`data_${index}_${simplePourField.name}`"
                  class="text-nowrap ml-2 oilwax-border text-right"
                  :class="{ 'font-bold': simplePourField.bold }"
                >
                  <span :class="{ 'opacity-0': pouringOption[simplePourField.name] === 0}">
                    {{ roundNumber(pouringOption[simplePourField.name], 2, 2) }} g
                  </span>
                </td>
                <td
                  v-if="simplePoursFiltered.length === 1"
                  :key="`space2_${index}_${simplePourField.name}`"
                >
                  <span class="mx-2"></span>
                </td>
              </template>
            </tr>
          </template>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { roundNumber, stringToNumber } from '@/helpers';

const OptionTable = () => import('@/components/oilwax_calculator/OptionTable.vue');

export default {
  name: 'Calculator',
  components: {
    OptionTable,
  },
  props: {
    loadId: Number,
  },
  computed: {
    canSave() {
      if (!this.isDualFieldOkay('container', 'container_mass')) {
        return false;
      }
      if (!this.isDualFieldOkay('fragrance', 'fragrance_pct')) {
        return false;
      }
      if (!this.isDualFieldOkay('colorant', 'colorant_pct')) {
        return false;
      }
      if (!this.isDualFieldOkay('additive1', 'additive1_pct')) {
        return false;
      }
      if (!this.isDualFieldOkay('additive2', 'additive2_pct')) {
        return false;
      }
      if (this.calculator.candle_count.length === 0) {
        return false;
      }
      if (this.calculator.wax.length === 0) {
        return false;
      }
      return true;
    },
    isLoading() {
      return this.loadingCount > 0;
    },
    hasReprint() {
      return this.reprint !== null;
    },
    currentDate() {
      return moment().format('DD.MM.YYYY');
    },
    candleCount() {
      return stringToNumber(this.calculator.candle_count);
    },
    minwax() {
      return this.calculator.option_two?.minwax ?? 0;
    },
    pouringOptionsWeight() {
      let weight = 0;
      this.pouringOptions.forEach((opt) => {
        weight += opt.total;
      });
      return weight;
    },
    simplePoursFiltered() {
      if (this.simplePours[0].jar_size !== 0) {
        return [this.simplePours[0]];
      }
      const simplePours = this.simplePours.filter((item) => item.jar_name !== 'Custom');
      const sortedJars = simplePours.sort((a, b) => {
        // Move jars with total = 0 to the end, and sort them by jar_size descending
        if (a.total === 0 && b.total === 0) {
          return b.jar_size - a.jar_size;
        }
        if (a.total === 0) return 1;
        if (b.total === 0) return -1;

        // First criterion: lower leftovers is better
        if (a.leftovers !== b.leftovers) {
          return a.leftovers - b.leftovers;
        }
        // Second criterion: lower amount is better
        if (a.amount !== b.amount) {
          return a.amount - b.amount;
        }
        // Third criterion: lower jar_size is better
        return a.jar_size - b.jar_size;
      });
      return sortedJars.slice(0, 3);
    },
    weight() {
      return stringToNumber(this.calculator.container_mass);
    },
    weightTotal() {
      return this.weight * this.candleCount;
    },
    dye() {
      return stringToNumber(this.calculator.option_two.dye);
    },
    dyeTotal() {
      return this.dye * this.candleCount;
    },
    weightTotalClass() {
      if (this.weightTotal >= this.minwax) {
        return 'text-success';
      }
      return 'text-danger';
    },
  },
  data() {
    return {
      loadingCount: 0,
      reprint: null,
      dataFields: [
        { name: 'candle_brand', option: 'candle_brand' },
        { name: 'container', option: 'container' },
        { name: 'wax', option: 'wax' },
        { name: 'container_mass', option: null },
        { name: 'colorant', option: 'colorant' },
        { name: 'colorant_pct', option: null },
        { name: 'fragrance', option: 'fragrance' },
        { name: 'fragrance_pct', option: 'fragrance_pct' },
        { name: 'additive1', option: 'additive' },
        { name: 'additive1_pct', option: 'additive_pct' },
        { name: 'additive2', option: 'additive' },
        { name: 'additive2_pct', option: 'additive_pct' },
        { name: 'candle_count', option: null },
        { name: 'custom_jar_size', option: null },
      ],
      jars: [
        { name: 'Custom', size: 0 },
        { name: 'Pitcher 8 kg', size: 8000 },
        { name: 'Pitcher 5 kg', size: 5000 },
        { name: 'Pitcher 2 kg', size: 2000 },
        { name: 'Pitcher 1 kg', size: 1000 },
      ],
      jarReserve: 0.8,
      pouringJars: [],
      pouringOptions: [],
      simplePours: [],
      simplePourFields: [
        { name: 'fragrance', text: 'Fragrance' },
        { name: 'wax', text: 'Wax' },
        { name: 'dye', text: 'Dye' },
        { name: 'additive1', text: 'Additive 1' },
        { name: 'additive2', text: 'Additive 2' },
        { name: 'leftovers', text: 'Leftovers' },
        { name: 'total', text: 'Total', bold: true },
      ],
      optionsDownload: null,
      options: {
        candle_brand: [],
        colorant: [],
        fragrance: [],
        fragrance_pct: [],
        container: [],
        wax: [],
        additive: [],
        additive_pct: [],
      },
      calculator: {
        candle_brand: '',
        fragrance: '',
        fragrance_pct: '',
        colorant: '',
        colorant_pct: '',
        additive1: '',
        additive1_pct: '',
        additive2: '',
        additive2_pct: '',
        container: '',
        container_mass: '',
        wax: '',
        candle_count: '',
        custom_jar_size: '',
        minwax: '',
        option_one: {
          fragrance: null,
          wax: null,
          dye: null,
          additive1: null,
          additive2: null,
          count: null,
          total: null,
          minwax: null,
        },
        option_two: {
          fragrance: null,
          wax: null,
          dye: null,
          additive1: null,
          additive2: null,
          count: null,
          total: null,
          minwax: null,
        },
      },
    };
  },
  methods: {
    prepareOptionText(key) {
      const options = [
        { value: '', text: '' },
      ];
      this.optionsDownload[key].options.forEach((option) => {
        options.push({
          id: option.option_id,
          value: option.option_value,
          text: option.option_value,
        });
      });
      this.options[key] = options;
    },
    prepareOptionNumeric(key) {
      const options = [
        { value: '', text: '' },
      ];
      this.optionsDownload[key].options.forEach((option) => {
        const valueRaw = stringToNumber(option.option_value);
        const valueFormatted = roundNumber(valueRaw, 0, 2);
        options.push({
          id: option.option_id,
          value: valueFormatted,
          text: valueFormatted,
        });
      });
      this.options[key] = options;
    },
    prepareOptionContainer() {
      const options = [
        { value: '', text: '' },
      ];
      this.optionsDownload.container_mass.options.forEach((option, index) => {
        const optionValues = option.option_value.split('|');
        const weightRaw = parseInt(optionValues[0], 10);
        const weightFormatted = roundNumber(weightRaw, 0, 0);
        const text = optionValues.length > 1 ? `${optionValues[1]}` : weightFormatted;
        options.push({
          id: option.option_id,
          value: index,
          fill: weightFormatted,
          text,
        });
      });
      this.options.container = options;
    },
    prepareOptions() {
      this.prepareOptionText('wax');
      this.prepareOptionText('candle_brand');
      this.prepareOptionText('additive');
      this.prepareOptionText('fragrance');
      this.prepareOptionNumeric('additive_pct');
      this.prepareOptionNumeric('fragrance_pct');
      this.prepareOptionContainer();
    },
    fetchColorants() {
      const filters = {
        has_recipe_french: '1',
        sort: 'pantoneNumber',
        page: 1,
        perPage: 9999,
      };
      this.loadingCount++;
      this.$http
        .get('/color')
        .query(filters)
        .then((res) => {
          const options = [
            { value: 0, text: 'None' },
          ];
          res.body.colors.forEach((color) => {
            options.push({
              id: color.id,
              value: color.id,
              fill: roundNumber(color.inwax_french, 2, 5),
              minwax: color.minwax_french,
              text: `${color.name} ${color.description}`,
            });
          });
          this.options.colorant = options;
        })
        .catch((err) => {
          this.$toast.error(`Failed to fetch colors: ${err.response.text}`);
        })
        .finally(() => {
          this.loadingCount--;
        });
    },
    fetchOptions() {
      this.loadingCount++;
      this.$http
        .get('/select/download')
        .then((res) => {
          this.optionsDownload = res.body.download;
          this.prepareOptions();
        })
        .catch((err) => {
          this.$toast(`Failed to fetch options: ${err.response.text}`);
        })
        .finally(() => {
          this.loadingCount--;
        });
    },
    calculatePouring() {
      const pouringJars = this.calculatePouringJars();
      const pouringOptions = this.calculatePouringOptions(pouringJars);
      this.pouringOptions = pouringOptions;
    },
    calculatePouringSimple() {
      const {
        fragrance,
        dye,
        additive1,
        additive2,
        wax,
        total,
      } = this.calculator.option_two;

      const options = [];
      this.jars.forEach((jar) => {
        const option = {
          amount: 0,
          jar_name: jar.name,
          jar_size: jar.size,
          jar_size_usable: jar.size * this.jarReserve,
          fragrance: 0,
          dye: 0,
          additive1: 0,
          additive2: 0,
          leftovers: 0,
          jars: 0,
          wax: 0,
          total: 0,
        };

        const multiplier = (
          this.weight === 0
            ? 0
            : Math.min(this.candleCount, Math.floor(option.jar_size_usable / this.weight))
        );
        option.fragrance = fragrance * multiplier;
        option.dye = dye * multiplier;
        option.additive1 = additive1 * multiplier;
        option.additive2 = additive2 * multiplier;
        option.wax = wax * multiplier;
        option.total = total * multiplier;

        const amount = option.total ? Math.ceil(this.weightTotal / option.total) : 0;
        option.amount = amount;

        const leftovers = option.total ? (option.total * option.amount) - this.weightTotal : 0;
        option.leftovers = leftovers;

        const jars = this.weight ? Math.floor((option.total * option.amount) / this.weight) : 0;
        option.jars = jars;

        if (option.total < this.calculator.minwax) {
          option.fragrance = 0;
          option.dye = 0;
          option.additive1 = 0;
          option.additive2 = 0;
          option.wax = 0;
          option.total = 0;
          option.leftovers = 0;
          option.jars = 0;
          option.amount = 0;
        }

        options.push(option);
      });
      this.simplePours = options;
    },
    calculatePouringOptions(pouringJars) {
      const pouringOptions = [];
      const {
        fragrance,
        dye,
        additive1,
        additive2,
        wax,
        total,
      } = this.calculator.option_two;
      pouringJars.forEach((pouringJar) => {
        const multiplier = pouringJar.weight / total;
        const pouringOption = {
          jar_name: pouringJar.jar,
          jar_weight: pouringJar.weight,
          fragrance: fragrance * multiplier,
          dye: dye * multiplier,
          additive1: additive1 * multiplier,
          additive2: additive2 * multiplier,
          wax: wax * multiplier,
          total: total * multiplier,
        };
        pouringOptions.push(pouringOption);
      });
      return pouringOptions;
    },
    calculatePouringJars() {
      // No weight is available
      if (this.weightTotal === 0) {
        return [];
      }

      const jars = [...this.jars];
      let remainingWeight = this.weightTotal;
      const minWeight = (
        this.dyeTotal === 0
          ? 0
          : Math.ceil(0.01 / (this.dyeTotal / this.weightTotal))
      );
      const solution = [];

      if (remainingWeight < minWeight) {
        remainingWeight = minWeight;
      }

      // Try to fit everything in one jar first
      const singleJar = jars.reverse().find((jar) => remainingWeight <= jar.size);
      if (singleJar) {
        solution.push({ jar: singleJar.name, weight: remainingWeight });
        return solution;
      }

      // Use biggest jar to fill most of it
      const bigJar = jars.reverse()[0];
      const bigTimes = Math.floor(remainingWeight / bigJar.size);
      for (let i = 0; i < bigTimes; i++) {
        solution.push({ jar: bigJar.name, weight: bigJar.size });
        remainingWeight -= bigJar.size;
      }

      // Fill the rest in the smallest jar
      if (remainingWeight < minWeight) {
        remainingWeight = minWeight;
      }
      const restJar = jars.reverse().find((jar) => remainingWeight <= jar.size);
      if (restJar) {
        solution.push({ jar: restJar.name, weight: remainingWeight });
        remainingWeight = 0;
      }

      if (remainingWeight > 0) {
        console.log('No solution available');
        return [];
      }
      return solution;
    },
    calculateCustomJar() {
      let customJarSize = stringToNumber(this.calculator.custom_jar_size);
      if (isNaN(customJarSize)) {
        customJarSize = 0;
      }
      this.jars[0].size = customJarSize;
    },
    calculateCandles() {
      if (this.minwax === 0 || this.weight === 0) return;
      const candleCount = Math.ceil(this.minwax / this.weight);
      this.calculator.candle_count = candleCount;
      this.calculateValues();
    },
    calculateCustomIdealJarSize() {
      let idealCustomJarSize = this.weight * this.candleCount;
      if (isNaN(idealCustomJarSize)) {
        idealCustomJarSize = '';
      }
      this.calculator.custom_jar_size = idealCustomJarSize;
      this.calculateValues();
    },
    calculateValues() {
      const fragrancePct = stringToNumber(this.calculator.fragrance_pct);
      const colorantPct = stringToNumber(this.calculator.colorant_pct);
      const additive1Pct = stringToNumber(this.calculator.additive1_pct);
      const additive2Pct = stringToNumber(this.calculator.additive2_pct);
      const containerMass = stringToNumber(this.calculator.container_mass);
      let candleCount = stringToNumber(this.calculator.candle_count);
      candleCount = candleCount === 0 ? 1 : candleCount;

      const colorantOption = this.options.colorant.find(
        (c) => c.value === this.calculator.colorant,
      );
      const minwax = colorantOption?.minwax ?? null;
      this.calculator.minwax = minwax;

      const optionOnePercentage = (
        100
        + fragrancePct
        + colorantPct
        + additive1Pct
        + additive2Pct
      );
      const optionOneWax = (100 / optionOnePercentage) * containerMass;
      this.calculator.option_one.wax = optionOneWax;
      this.calculator.option_one.fragrance = optionOneWax * (fragrancePct / 100);
      this.calculator.option_one.dye = optionOneWax * (colorantPct / 100);
      this.calculator.option_one.additive1 = optionOneWax * (additive1Pct / 100);
      this.calculator.option_one.additive2 = optionOneWax * (additive2Pct / 100);
      this.calculator.option_one.count = candleCount;
      this.calculator.option_one.total = containerMass;
      this.calculator.option_one.minwax = minwax;

      this.calculator.option_two.fragrance = containerMass * (fragrancePct / 100);
      this.calculator.option_two.dye = containerMass * (colorantPct / 100);
      this.calculator.option_two.additive1 = containerMass * (additive1Pct / 100);
      this.calculator.option_two.additive2 = containerMass * (additive2Pct / 100);
      this.calculator.option_two.wax = (
        containerMass
        - this.calculator.option_two.fragrance
        - this.calculator.option_two.dye
        - this.calculator.option_two.additive1
        - this.calculator.option_two.additive2
      );
      this.calculator.option_two.count = candleCount;
      this.calculator.option_two.total = containerMass;
      this.calculator.option_two.minwax = minwax;
      this.calculateCustomJar();
      // this.calculatePouring();
      this.calculatePouringSimple();
    },
    fillValue(sourceKey, destinationKey) {
      const selectedOption = this.options[sourceKey].find(
        (option) => option.value === this.calculator[sourceKey],
      );
      this.calculator[destinationKey] = selectedOption?.fill ?? '';
      this.calculateValues();
    },
    roundNumber(value, minimumFractionDigits, maximumFractionDigits) {
      return roundNumber(value, minimumFractionDigits, maximumFractionDigits);
    },
    reset() {
      this.reprint = null;
      this.calculator.candle_brand = '';
      this.calculator.fragrance = '';
      this.calculator.fragrance_pct = '';
      this.calculator.colorant = '';
      this.calculator.colorant_pct = '';
      this.calculator.additive1 = '';
      this.calculator.additive1_pct = '';
      this.calculator.additive2 = '';
      this.calculator.additive2_pct = '';
      this.calculator.container = '';
      this.calculator.container_mass = '';
      this.calculator.wax = '';
      this.calculator.candle_count = '';
      this.calculator.custom_jar_size = '';
      this.calculator.minwax = '';
      this.calculateValues();
    },
    print(saveData) {
      saveData = saveData === undefined ? false : saveData;
      if (saveData) {
        this.onPrint();
      }
      window.print();
    },
    onPrint() {
      const saveData = this.getSaveData();
      this.loadingCount++;
      this.$http
        .post('/oilwax')
        .send({ data: saveData })
        .then((res) => {
          this.reprint = res.body.record;
        })
        .catch((err) => {
          this.$toast.error(`Failed to save data: ${err.response.text}`);
        })
        .finally(() => {
          this.loadingCount--;
        });
    },
    loadSaveData(loadId) {
      if (!loadId) {
        return;
      }

      const fieldsReady = this.dataFields
        .filter((field) => field.option)
        .every((field) => (this.options[field.option].length > 0));
      if (!fieldsReady) {
        setTimeout(() => {
          this.loadSaveData(loadId);
        }, 250);
        return;
      }

      this.$http
        .get(`/oilwax/${this.loadId}`)
        .then((res) => {
          const { record } = res.body;
          this.reprint = record;
          this.dataFields.forEach((field) => {
            if (field.option === null) {
              this.calculator[field.name] = record[field.name];
              return;
            }
            const option = this.options[field.option].find(
              (opt) => opt.id === record[field.name],
            );
            this.calculator[field.name] = option ? option.value : '';
          });
          this.calculateValues();
        })
        .catch((err) => {
          this.$toast.error(`Failed to load data: ${err.response.text}`);
        })
        .finally(() => {
          this.loadingCount--;
          this.$emit('loaded');
        });
    },
    getSaveData() {
      const saveData = {};
      this.dataFields.forEach((field) => {
        if (field.option === null) {
          saveData[field.name] = this.calculator[field.name];
          return;
        }
        const option = this.options[field.option].find(
          (opt) => opt.value === this.calculator[field.name],
        );
        saveData[field.name] = option?.id;
      });
      if (this.reprint) {
        saveData.id = this.reprint.id;
      }
      if (this.calculator.colorant === 0) {
        saveData.colorant = 0;
      }
      return saveData;
    },
    isDualFieldOkay(fieldOne, fieldTwo) {
      const fieldOneLength = (
        this.calculator[fieldOne] === null
          ? 0
          : this.calculator[fieldOne].toString().length
      );
      const fieldTwoLength = (
        this.calculator[fieldTwo] === null
          ? 0
          : this.calculator[fieldTwo].toString().length
      );
      if (fieldOne === 'colorant' && this.calculator[fieldOne] === 0) {
        return true;
      }
      if (fieldOneLength > 0 && fieldTwoLength === 0) {
        return false;
      }
      if (fieldTwoLength > 0 && fieldOneLength === 0) {
        return false;
      }
      return true;
    },
    fragranceCalculator() {
      const fragranceWeight = parseInt(prompt('How much fragrance do you have?'), 10);
      if (isNaN(fragranceWeight)) {
        return;
      }
      const fragrancePct = stringToNumber(this.calculator.fragrance_pct);
      const perCandle = this.weight * (fragrancePct / 100);
      const candleCount = Math.floor(fragranceWeight / perCandle);
      this.calculator.candle_count = candleCount;
    },
  },
  created() {
    this.fetchOptions();
    this.fetchColorants();
    this.calculateValues();
    this.loadSaveData(this.loadId);
  },
};
</script>

<style lang="scss">

@media print {
  .print-date {
    position: absolute;
    right: 36px;
    top: 22px;
  }
}

.oilwax-calculator .input-group-append>.input-group-text {
  width: 32px;
}

</style>
